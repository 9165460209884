<template>
  <div class="bind" :class="{noScollForNoLogin_safari:os.safari}">
      <div class="bind-form">
          <h2>微信/微博登录</h2>
          <!-- <p class="cue"><i>微信注册用户</i>（请用已绑定的邮箱和密码登录）</p> -->

          <div class="form form_common">
            <van-form @submit="onSubmit" ref="b_form">
                <van-field v-model="email" autocomplete="off" type="text" name="email" placeholder="邮箱账号" :rules="[{ required: true, message: '请输入邮箱账号' },{pattern,message: '邮箱账号格式错误'}]"/>
                <van-field v-model="password" autocomplete="off" type="password" name="password" placeholder="登录密码" :rules="[{ required: true, message: '请输入登录密码' }]"/>
            </van-form>

            <c-button bClass="btn_submit" @btnClick="onBind">确定</c-button>

           
           
          </div>
      </div>

       <div class="cue2">
            <p>由于第三号限制微信/微博无法直接登录享设计移动端</p>
            <p>请至PC官网“我的信息”页面绑定邮箱账号和登录密码</p>
        </div>
  </div>
</template>

<script>
import { Field, Form } from "vant";
import CButton from "../../components/form/CButton.vue";
import CImage from "../../components/CImage.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        [CButton.name]: CButton,
        [CImage.name]: CImage,
        [Field.name]: Field,
        [Form.name]: Form,
    },
    data() {
        return {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            email: "",
            password: "",
        };
    },
    computed: {
        ...mapState({
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestEmailLogin: "user/requestEmailLogin",
        }),
        onSubmit(e) {
            this.requestEmailLogin(e).then(() => {

                //跳回到上一个页面
                let _hisPath = sessionStorage.getItem('no_login_route')
                if (_hisPath == '/products') {
                    this.$router.replace({
                        name: 'products',
                        params: { keywords: this.$store.state.search.historyKeyWords, option: sessionStorage.getItem('option') },
                        query: { keywords: this.$store.state.search.historyKeyWords, option: sessionStorage.getItem('option') }
                    });
                } else {
                    this.$router.replace({
                        path: _hisPath || '/',
                    });
                }
            });
        },
        onBind() {
            this.$refs.b_form.submit();
        },
    },
    created() {
        let _uuid = this.$cookies.isKey("user_id");
        if (_uuid) {
            this.$router.replace({
                name: "home",
            });
        }
    },
};
</script>

<style>
.bind{padding-top: 47px; height: 100vh; background: #fff;}
.bind-form{padding:20px 20px 0;}
.bind-form h2{font-size: 20px;color: #2E2E30; text-align: center; font-weight: normal; margin-bottom: 20px;}
.bind-form .cue{font-size: 14px;color: #B3B3B3; margin-top: 20px; margin-bottom: 8px;}
.bind-form .cue i{font-style: normal; color:#F85659;}
.bind-form .btn_submit{margin-top: 32px;}
.bind .cue2{width: 100%; position: fixed; left: 0; bottom: 64px; z-index: 1;}
.bind .cue2 p{ text-align: center;font-size: 12px;color: #B3B3B3;}
</style>